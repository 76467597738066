import "../style/App.css";
import { Component } from "react";
import Song from "./Song.js";
import { Grid } from "@mui/material";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Song
              songName={"Thnks fr th Mmrs"}
              imageURL={
                "https://i.scdn.co/image/ab67616d0000b273ff59837e170f95a373afc0ec"
              }
            ></Song>
          </Grid>
          <Grid item xs={3}>
            <Song
              songName={"Summer Days"}
              imageURL={
                "https://i.scdn.co/image/ab67616d0000b273d7658a5bf66a2f3f11878bdf"
              }
            ></Song>
          </Grid>
          <Grid item xs={3}>
            <Song
              songName={"Miss Murder"}
              imageURL={
                "https://i.scdn.co/image/ab67616d0000b2736c050d183c1fff7158c7bcd3"
              }
            ></Song>
          </Grid>
          <Grid item xs={3}>
            <Song
              songName={"Meanwhile... In Genova"}
              imageURL={
                "https://i.scdn.co/image/ab67616d0000b2736e6b76438b03ae85116f68f6"
              }
            ></Song>
          </Grid>
        </Grid>

        <div className="info">
          Work in progress, made with love by{" "}
          <a href="https://nyturhan.dev">nyturhan</a>
        </div>
      </div>
    );
  }
}

export default App;
