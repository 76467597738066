import "../style/Song.css";
import { Component } from "react";
import { Card, CardContent, CardMedia } from "@mui/material";

class Song extends Component {
  render() {
    return (
      <Card
        className="Song"
        sx={{
          "&:hover": {
            backgroundColor: "#282c34",
            boxShadow: 20,
            color: "white",
            transform: "scale3d(1.05,1.05,1)",
          },
          bgcolor: "#eeeee4",
        }}
      >
        <CardMedia
          image={this.props.imageURL}
          style={{ height: 0, paddingTop: "100%" }}
        ></CardMedia>
        <CardContent>{this.props.songName}</CardContent>
      </Card>
    );
  }
}

export default Song;
